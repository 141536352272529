import React from 'react';



function NavBar(props) {

	return(
		<div className="nav">
			<div className="left-nav">
				<h1 className="navbar-title">mira</h1><h1 className="last_name">taferner</h1>
				<div className="logo-line"></div>
			</div>
		</div>
	)
}





export default NavBar;