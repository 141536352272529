import img1 from './images/Fitz_landingpage.jpg';
import img2 from './images/fitz_images/Fitz_1.jpg';
import img3 from './images/fitz_images/Fitz_2.jpg';
import img4 from './images/fitz_images/Fitz_3.jpg';
import img5 from './images/fitz_images/Fitz_4.jpg';
import img6 from './images/fitz_images/Fitz_5.jpg';
import img7 from './images/fitz_images/Fitz_6.jpg';
import img8 from './images/fitz_images/Fitz_7.jpg';
import img9 from './images/fitz_images/Fitz_8.jpg';
import img10 from './images/fitz_images/Fitz_9.jpg';
import img11 from './images/fitz_images/Fitz_10.jpg';
import img12 from './images/fitz_images/Fitz_11.jpg';
import img13 from './images/Zeitscheine_Landingpage.jpg'
import img14 from './images/zeit_images/Zeitschein_1.jpg'
import img15 from './images/zeit_images/Zeitschein_2.jpg'
import img16 from './images/zeit_images/Zeitschein_3.jpg'
import img17 from './images/zeit_images/Zeitschein_4.jpg'
import img18 from './images/Haufe_Landingpage.jpg'
import img19 from './images/haufe_images/Haufe_TG_1.jpg'
import img20 from './images/haufe_images/Haufe_TG_2.jpg'
import img21 from './images/haufe_images/Haufe_TG_3.jpg'
import img22 from './images/BayLGB_Landingpage.jpg'
import img23 from './images/bay_images/BayLGB_1.jpg'
import img24 from './images/PBB_Landingpage.jpg'
import img25 from './images/pbb_images/pbb_Weihnachtsgruesse_2016_klein.mp4'
import img27 from './images/pbb_images/RZ_pbb_Neujahrskarte_digital_2017_klein.mp4'
import img29 from './images/Kinderliederbuch_landingpage.jpg'
import img30 from './images/kinder_images/Kinderliederbuch_1.jpg'
import img31 from './images/kinder_images/Kinderliederbuch_2.jpg'
import img32 from './images/kinder_images/Kinderliederbuch_3.jpg'
import img33 from './images/kinder_images/Kinderliederbuch_4.jpg'
import img34 from './images/WillowGareth_landingpage.jpg'
import img35 from './images/wg_images/WilloandGareth_1.jpg'
import img36 from './images/wg_images/WilloandGareth_2.jpg'
import img37 from './images/wg_images/WilloandGareth_3.jpg'
import img38 from './images/wg_images/WilloandGareth_4.jpg'
import img39 from './images/wg_images/WilloandGareth_5.jpg'
import img40 from './images/wg_images/WilloandGareth_6.jpg'
import img41 from './images/KarinaWard_landingpage.jpg'
import img42 from './images/karina_images/KarinaWard_1.jpg'
import img43 from './images/karina_images/KarinaWard_2.jpg'
import img44 from './images/karina_images/KarinaWard_3.jpg'
import img45 from './images/karina_images/KarinaWard_4.jpg'
import img46 from './images/karina_images/KarinaWard_5.jpg'
import img47 from './images/karina_images/KarinaWard_6.jpg'
import img48 from './images/karina_images/KarinaWard_7.jpg'
import img49 from './images/karina_images/KarinaWard_8.jpg'
import img50 from './images/Theresa_landingpage.jpg'
import img51 from './images/theresa_images/Theresa_1.jpg'
import img52 from './images/theresa_images/Theresa_2.jpg'
import img53 from './images/theresa_images/Theresa_3.jpg'
import img54 from './images/theresa_images/Theresa_4.jpg'
import img55 from './images/VictorYork_Landingp.jpg'
import img56 from './images/victor_images/VictorYork_1.jpg'
import img57 from './images/Conradt_Landingpage.jpg'
import img58 from './images/conradt_images/Conradt_1.jpg'
import img59 from './images/conradt_images/Conradt_2.jpg'
import img60 from './images/Grafikhaus_Landingpage.jpg'
import img61 from './images/grafik_images/GH_Weihnachtskarte_neues Format.mp4'
import img62 from './images/grafik_images/RZ_GH_Weihanchtskarte_2017_einzeln.mp4'
import img63 from './images/SpeakUp_landingpage.jpg'
import img64 from './images/speakup_images/SpeakUp_1.jpg'
import img65 from './images/speakup_images/SpeakUp_2.jpg'
import img66 from './images/Kloepfer_Landingpage.jpg'
import img67 from './images/kloepfer_images/Kloepfer_1.jpg'
import img68 from './images/kloepfer_images/Kloepfer_2.jpg'
import img69 from './images/kloepfer_images/Kloepfer_3.jpg'
import img70 from './images/kloepfer_images/Kloepfer_4.jpg'
import img71 from './images/kloepfer_images/Kloepfer_5.jpg'
import img72 from './images/kloepfer_images/Kloepfer_6.jpg'
import img73 from './images/kloepfer_images/Kloepfer_7.jpg'
import img74 from './images/kloepfer_images/Kloepfer_8.jpg'
import img75 from './images/kloepfer_images/Kloepfer_9.jpg'
import img76 from './images/kloepfer_images/Kloepfer_10.jpg'
import img77 from './images/kloepfer_images/Kloepfer_11.jpg'
import img78 from './images/Gioia_landingpage.jpg'
import img79 from './images/gioia_images/Gioia_1.jpg'
import img80 from './images/gioia_images/Gioia_2.jpg'
import img81 from './images/gioia_images/Gioia_3.jpg'
import img82 from './images/gioia_images/Gioia_4.jpg'
import img83 from './images/Regsam_Landingpage.jpg'
import img84 from './images/regsam_images/Regsam_1.jpg'
import img85 from './images/Heus_Landingpage.jpg'
import img86 from './images/heus_images/Heus_1.jpg'
import img87 from './images/heus_images/Heus_2.jpg'
import img88 from './images/Joe_Landingpage.jpg'
import img89 from './images/joe_images/Joe_1.jpg'
import img90 from './images/joe_images/Joe_2.jpg'
import img91 from './images/joe_images/Joe_3.jpg'
import img92 from './images/joe_images/Joe_4.jpg'
import img93 from './images/joe_images/Joe_5.jpg'
import img94 from './images/joe_images/Joe_6.jpg'
import img95 from './images/EasyFrank_Landingpage.jpg'
import img96 from './images/frank_images/Easy_Frank_1.jpg'
import img97 from './images/frank_images/Easy_Frank_2.jpg'
import img98 from './images/Theegarten_landingpage.jpg'
import img99 from './images/theegarten_images/Theegarten_1.jpg'
import img100 from './images/Lustwerk_Landingpage.jpg'
import img101 from './images/lustwerk_images/Lustwerk_1.jpg'
import img102 from './images/lustwerk_images/Lustwerk_2.jpg'
import img103 from './images/Bookyourcareer_landingpage.jpg'
import img104 from './images/book_images/Book-your-career_1.jpg'
import img105 from './images/book_images/Book-your-career_2.jpg'
import img106 from './images/book_images/Book-your-career_3.jpg'
import img107 from './images/Logos_Landingpage.jpg'
import img108 from './images/logo_images/Logo_1.jpg'
import img109 from './images/logo_images/Logo_2.jpg'
import img110 from './images/logo_images/Logo_3.jpg'
import img111 from './images/logo_images/Logo_4.jpg'
import img112 from './images/logo_images/Logo_5.jpg'
import img113 from './images/logo_images/Logo_6.jpg'
import img114 from './images/logo_images/Logo_7.jpg'
import img115 from './images/logo_images/Logo_8.jpg'
import img116 from './images/logo_images/Logo_9.jpg'
import img125 from './images/logo_images/Logo_11.jpg'
import img117 from './images/Cover_Landingpage.jpg'
import img118 from './images/cover_images/Cover_1.jpg'
import img119 from './images/cover_images/Cover_2.jpg'
import img120 from './images/cover_images/Cover_3.jpg'
import img121 from './images/cover_images/Cover_4.jpg'
import img122 from './images/cover_images/Cover_5.jpg'
import img123 from './images/cover_images/Cover_6.jpg'
import img124 from './images/cover_images/Cover_7.jpg'

var projectObject = 
			[
				{
					title: "print",
					subtitle: "CD booklet Michael Fitz",
					modal_text: "The objective was to create a piece of art for every song of this album. By selecting images that matched each song and melting them together, every song received space in the booklet to feel at home. I spent hours listening to the songs. This project pimped my Photoshop skills!",
					img: img1,
					modal: [img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12],
					id: 1
				},
				{
					title: "other",
					subtitle: "The new currency",
					modal_text: "A genuine birthday present in a money ruled world: time bills! Each one connected to an activity, not an amount of money. City trip, movie or game night, hiking weekend… that's real value!!!",
					img: img13,
					modal: [img14, img15, img16, img17 ],
					id: 2
				},
				{
					title: "print",
					subtitle: "Cover design for a book series",
					modal_text: "Scribbling… it’s fun to get creative on a piece of gum wrapper or on book covers! This particular format of self help books was celebrating their 20 year anniversary and I created sketch notes for six different books on six different topics and the matching presenter box.",
					img: img18,
					modal: [img19, img20, img21 ],
					id: 3
				},
				{
					title: "print",
					subtitle: "Flyer for a non-profit organization",
					modal_text: "BayLGB is a non-profit that collaborates closely with the judiciary system to provide support for men and women when they've been released from prison. After the layout for the first flyer was done we customized versions for every state in Germany.",
					img: img22,
					modal: [img23],
					id: 4
				},
				{
					title: "digital",
					subtitle: "Animated christmas cards for a bank",
					modal_text: "In this project I worked closely with a very talented illustrator. The snowman idea gives a friendly touch to a serious bank that specializes in real estate financing and public investment. The bank loved it and brought the snowman back for Christmas the following year.",
					img: img24,
					modal: [img25, img27],
				 id: 5 
				},
				{
					title: "other",
					subtitle: "A children's songbook with heart",
					modal_text: "A unique songbook created as a special gift: in a team approach we researched and picked children's songs, recorded them and got artsy with paper and pen. Finally, I compiled it into a 22 page book.",
					img: img29,
					modal: [img30, img31, img32, img33],
					id: 6
				},
				{
					title: "print",
					subtitle: "Wedding",
					modal_text: "Willow & Gareth’s ideas for their wedding material were beautiful and it was easy for me to feel what they were looking for. The design was used on invitations, RSVP cards, info letters, place cards, the food and drink menus, and a unique guest tree.",
					img: img34,
					modal: [img35, img36, img37, img38, img39, img40],
					id: 7
				},
				{
					title: "other",
					subtitle: "Wall tattoo for an underwater scenery",
					modal_text: "The task was to create a unique underwater scenery for the wall above the client’s son`s bed to match her favourite song: Yellow Submarine. I designed a customized submarine and different underwater animals with personality.",
					img: img41,
					modal: [img42, img43, img44, img45, img46, img47, img48, img49],
					id: 8
				},
				{
					title: "print // logo",
					subtitle: "Logo & stationary Theresa cosmetics",
					modal_text: "When branding a company with your own name it is important to identify with the logo and the look created. The logo, flyers, business cards, leaflets, posters, vouchers, christmas cards, the interior of the studios… It all speaks Theresa's language.",
					img: img50,
					modal: [img51, img52, img53, img54],
					id: 9
				},
				{
					title: "other",
					subtitle: "Icon-set for an online shop",
					modal_text: "Victor York is an online shop for custom tailored shirts. I designed an icon-set that was used online to help customers navigate through the customization tool for choosing fit, collar or cuff style.",
					img: img55,
					modal: [img56],
					id: 10
				},
				{
					title: "logo // print",
					subtitle: "Logo & stationary for a media company",
					modal_text: "The company I worked for at the time had created the visual identity for “Conradt and friends production” 15 years ago. It was time for something new. I relaunched the logo and stationary giving it a modern and fresh look.",
					img: img57,
					modal: [img58, img59],
					id: 11
				},
				{
					title: "digital",
					subtitle: "Animated christmas cards for Grafikhaus",
					modal_text: "Animations became a new and playful field for me when I started animating ads and headers for websites. Here are two christmas cards I animated for my company - short and simple. Just the way I like it.",
					img: img60,
					modal: [img61, img62],
				 id: 12 
				},
				{
					title: "logo // print",
					subtitle: "Logo & stationary for a language school",
					modal_text: "A logo in a playful, child appealing look but also serious enough to demonstrate the school's professional approach in teaching children a foreign language. No books required: what a great way to learn a language!",
					img: img63,
					modal: [img64, img65],
					id: 13
				},
				{
					title: "print",
					subtitle: "Marketing materials of all sorts",
					modal_text: "Working for Klöpfer means lots of challenging projects, a strict CD, tight deadlines and many people involved in the design process. I created marketing materials, product packaging, presentations and so much more. This is a small sample of my 5 years working for this client.",
					img: img66,
					modal: [img67, img68, img69, img70, img71, img72, img73, img74, img75, img76, img77],
					id: 14
				},
				{
					title: "logo // print",
					subtitle: "Logo & stationary for jeweler",
					modal_text: "Every piece of jewelry Gioia makes is unique. Feminine and strong, clean, precious, elegant and light. I designed a logo to match the simple and beautiful handmade earrings and necklaces.",
					img: img78,
					modal: [img79, img80, img81, img82],
					id: 15
				},
				{
					title: "print",
					subtitle: "Brochure for a non-profit organization",
					modal_text: "REGSAM is a non-profit organization also called »the nerve system of Munich«. Their goal is to build a network that connects people in need with the right place to go. Hospitals, refugee homes, rehab counselors, safe homes… This brochure is a telephone book for the social service sector.",
					img: img83,
					modal: [img84],
					id: 16
				},
				{
					title: "logo // print",
					subtitle: "Logo & stationary for a manufacturer",
					modal_text: "The objective was to create a logo that brands the company in a heavy and strong, yet agile way to match the wide-buckets the company distributes.",
					img: img85,
					modal: [img86, img87],
					id: 17
				},
				{
					title: "print",
					subtitle: "Brochure for exterior decking",
					modal_text: "A 50 page brochure made annually in spring to showcase the company’s newest outdoor decking materials.  My work also included creating the illustrations for the »how to« page, a step by step instruction for the do-it-yourselfer.",
					img: img88,
					modal: [img89, img90, img91, img92, img93, img94],
					id: 18
				},
				{
					title: "other",
					subtitle: "CD cover design",
					modal_text: "Designing CD covers is fun. It’s even more fun when the artist let's you in on what it is that drives him to create their music. Empathy is important in order to transport the message of your project with power. This is especially true when it comes to music!",
					img: img95,
					modal: [img96, img97],
					id: 19
				},
				{
					title: "other",
					subtitle: "Icon-set for a packaging company",
					modal_text: "Part of the relaunch we did for one of the worldwide leading manufacturers in the packaging technology sector was to create a set of icons to show the different wrapping techniques available for machines. I enjoyed visualizing the differences through downsizing and simplification.",
					img: img98,
					modal: [img99],
					id: 20
				},
				{
					title: "logo // print",
					subtitle: "Logo & stationary for massage therapist",
					modal_text: "Designing this logo was interesting. I didn’t know much about tantra massages until I met this client. I liked the name she came up with. It is provocative and honest at the same time.",
					img: img100,
					modal: [img101, img102],
					id: 21
				},
				{
					title: "print",
					subtitle: "Marketing materials and giveaways",
					modal_text: "Marketing material for an event aimed to inform about the book trade and publishing industry, and encourage young adults to seek a career in this sector. This event is now in its 5th year and has helped the industry become more attractive to young people.",
					img: img103,
					modal: [img104, img105, img106],
					id: 22
				},
				{
					title: "logo",
					subtitle: "An overview",
					modal_text: "“Creativity is nothing but a mind set free.” –Torrie T. Asai",
					img: img107,
					modal: [img108, img109, img110, img111, img112, img113, img114, img115, img116, img125],
					id: 23
				},
				{
					title: "print",
					subtitle: "Book covers worth showing",
					modal_text: "I enjoy playing around with information and finding new ways to prioritize text elements on a page. Book covers are great for that, especially when the author asks for you creativity. Not all of these covers actually made it to the shops but I like them. So here they are!",
					img: img117,
					modal: [img118, img119, img120, img121, img122, img123, img124],
					id: 24
				},
			]    

export default projectObject;